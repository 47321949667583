// TODO: refactor 1. move to store/mixin/composable
// TODO: refactor 2. separate XP and reward logic
import levelConfig from '@/student/static-json/levelsV2.json';
import Avatars from '@/core/static-json/avatar/AvatarsList3d.json';
import cardsJSON from '@/core/static-json/cardsData.json';
import decorationsJSON from '@/core/static-json/decorationsData.json';
import customAvatarItems from '@/core/static-json/avatar/customAvatar.json';
import hairColors from '@/core/static-json/avatar/hairColors.json';
import bodyColors from '@/core/static-json/avatar/bodyColors.json';
import { randomArrayElement } from '@/core/helpers/utils.js';

export function getLevelForPlayer(player) {
    let xp = Math.round(player?.homeGameV10SimpleTreeSocial?.xp);

    for (let i = Object.keys(levelConfig).length; i > 0; i--) {
        if (xp >= levelConfig[`level${i}`]) {
            return i;
        }
    }

    return 1;
}

export function getLevelByXp(xp) {
    for (let i = Object.keys(levelConfig).length; i >= 0; i--) {
        if (xp >= levelConfig[`level${i}`]) {
            return i;
        }
    }
}

export function getCurrentLevelXpByXp(xp) {
    for (let i = Object.keys(levelConfig).length; i >= 0; i--) {
        if (xp >= levelConfig[`level${i}`]) {
            if (i === Object.keys(levelConfig).length) return Infinity;

            return levelConfig[`level${i}`];
        }
    }
}

export function getNextLevelByXp(xp) {
    for (let i = Object.keys(levelConfig).length; i >= 0; i--) {
        if (xp >= levelConfig[`level${i}`]) {
            if (i === Object.keys(levelConfig).length) {
                return Infinity;
            }

            return levelConfig[`level${i + 1}`];
        }
    }
}

export function getPlayerAvatar(player) {
    const avatars = Avatars[0].avatars.map((avatar) => avatar.id);

    return player.homeGameV10SimpleTreeSocial
        ? player.homeGameV10SimpleTreeSocial.avatar
        : avatars[Math.floor(Math.random() * avatars.length)];
}

export function getPlayerCustomAvatar(player) {
    return player?.homeGameV10SimpleTreeSocial?.customAvatar || null;
}

export function getPlayerBackground(player) {
    return player?.homeGameV10SimpleTreeSocial?.background || null;
}

export function getPlayerFrame(player) {
    return player?.homeGameV10SimpleTreeSocial?.frame || 'no-frame';
}

function pickRandom() {
    // https://stackoverflow.com/questions/62413615/making-a-rarity-chance-in-javascript
    const rarities = [
        {
            type: 'common',
            chance: 0,
        },
        {
            type: 'rare',
            chance: 23,
        },
        {
            type: 'epic',
            chance: 8,
        },
        {
            type: 'legendary',
            chance: 3,
        },
    ];

    // Calculate chances for common
    let filler =
        100 -
        rarities.map((r) => r.chance).reduce((sum, current) => sum + current);

    if (filler <= 0) {
        console.log('chances sum is higher than 100!');

        return;
    }
    // Create an array of 100 elements, based on the chances field
    let probability = rarities
        .map((r, i) => Array(r.chance === 0 ? filler : r.chance).fill(i))
        .reduce((c, v) => c.concat(v), []);

    // Pick one
    let pIndex = Math.floor(Math.random() * 100);

    let rarity = rarities[probability[pIndex]];

    return rarity.type;
}

export function getChestReward(player) {
    const GatchaAvatars = Avatars.map((group) => group.avatars)
        .flatMap((list) => list)
        .filter((avatar) => avatar.type === 'battle-pass-gacha');

    const claimedRewards =
        player?.studentInfo?.battlepass?.claimedRewards || [];

    function getGachaPool() {
        let pool = [];

        // 5 retry attempts
        for (let i = 0; i < 5; i++) {
            const rarity = pickRandom();

            pool = GatchaAvatars.filter(
                (avatar) =>
                    !claimedRewards.includes(avatar.id) &&
                    avatar.rarity === rarity,
            );

            if (pool.length > 0) break;
        }

        return pool;
    }

    const gatchaPool = getGachaPool();

    if (gatchaPool.length > 0) {
        return gatchaPool[Math.floor(Math.random() * gatchaPool.length)];
    } else {
        return GatchaAvatars.find((item) => !claimedRewards.includes(item.id));
    }
}

export function getCardReward(player, isRare) {
    function getAvailableGroups() {
        const isSubscribedUser = ['active', 'trialing'].includes(
            player.subscriptionStatus,
        );

        if (typeof isRare !== 'undefined') {
            return isRare
                ? cardsJSON.filter((group) => group.premium)
                : cardsJSON.filter((group) => !group.premium);
        }

        return isSubscribedUser
            ? cardsJSON
            : cardsJSON.filter((group) => !group.premium);
    }

    const availableCardGroups = getAvailableGroups();
    const cardsPool = availableCardGroups.flatMap((item) => item.items);
    const claimedCards = player?.studentInfo?.cards || [];

    const uniquePool = cardsPool.filter(
        (card) => !claimedCards.find((claimed) => claimed.name === card.name),
    );

    if (uniquePool.length > 0) {
        return uniquePool[Math.floor(Math.random() * uniquePool.length)];
    } else {
        return cardsPool[Math.floor(Math.random() * cardsPool.length)];
    }
}

export function getPetCategory(id) {
    let grouped = {};

    cardsJSON.forEach((group) => {
        group.items.forEach((card) => {
            grouped[card.name] = group.group;
        });
    });

    let groupName = grouped[id];

    if (groupName === 'animal kingdom') {
        return 'kingdom';
    }

    return groupName.slice(0, -1);
}

export function getDecorationReward(player) {
    const claimedRewards =
        player?.studentInfo?.battlepass?.claimedRewards || [];
    const claimedItems = player?.studentInfo?.battlepass?.items || [];

    const decorationPool = decorationsJSON
        .flatMap((item) => item.items)
        .filter(
            (decor) =>
                !claimedRewards.includes(decor.name) ||
                !claimedItems.find(({ id }) => id === decor.id),
        );

    return randomArrayElement(decorationPool);
}

export function getAvatarItemsReward(player, rarity) {
    const claimedItems = player?.studentInfo?.battlepass?.items || [];
    const rewardsFilter = (arr) =>
        arr.filter(
            (elem) =>
                elem.id &&
                elem.rarity === rarity &&
                !claimedItems.find(({ id }) => id === elem.id),
        );

    const rewardsPool = customAvatarItems.reduce(
        (acc, { group, items }, idx) => {
            if (group === 'mask') return acc;

            acc.push(...rewardsFilter(items));

            if (!idx) {
                acc.push(...rewardsFilter(hairColors));
                acc.push(...rewardsFilter(bodyColors));
            }

            return acc;
        },
        [],
    );

    return randomArrayElement(rewardsPool);
}

export function checkIfMistakeTooltipShouldBeShown(
    currentSkillType,
    currentQuestion,
) {
    // @todo: to make other topics and types work, the getCorrectAnswer()
    // needs to fixed to use Topics API.
    const types = [
        'TYPE_ADDITION',
        'TYPE_SUBTRACTION',
        'TYPE_MULTIPLICATION',
        'TYPE_DIVIDING',
    ];

    const unsupportedSubTopics = ['fractions', 'integers'];

    return (
        types.includes(currentSkillType) &&
        !unsupportedSubTopics.includes(currentQuestion.topic)
    );
}
