/* global mixpanel */

export const HOME_PLAY_EVENTS = {
    HOME_PLAY_VISIT_PAGE: 'homePlayVisitPage',
    HOME_PLAY_CLICK_GET_PRINTOUTS: 'homePlayClickGetPrintouts',
    HOME_PLAY_CLICK_SEND_EMAIL: 'homePlayClickSendEmail',
    HOME_PLAY_CLICK_SEE_CREATE_CLASS_MODAL: 'homePlaySeeCreateClassModal',
    EMAIL_MODAL_CLICK_COPY: 'emailModalClickCopy',
    EMAIL_MODAL_CLICK_CLOSE: 'emailModalClickClose',
    CLICK_PRINT_IN_PARENT_PRINTOUT: 'clickPrintInParentPrintout',
    CLICKED_GAME_NIGHT_CTA: 'clickedGameNightCTA',
    CLOSED_GAME_NIGHT_BANNER: 'closedGameNightBanner',
};

export const HOME_PLAY_METHODS = {
    homePlayVisitPage() {
        mixpanel.track('Visit Home Play page');
    },
    homePlayClickGetPrintouts({ teacherUi = 'old' }) {
        mixpanel.track('Home Play clicked Get Printouts', {
            'Teacher UI Version': teacherUi,
        });
    },
    clickPrintInParentPrintout({ classCode }) {
        mixpanel.track('Clicked Print List btn in parent printout', {
            'Class code': classCode,
        });
    },
    homePlayClickSendEmail({ teacherUi = 'old' }) {
        mixpanel.track('Home Play clicked Send Email', {
            'Teacher UI Version': teacherUi,
        });
    },
    homePlaySeeCreateClassModal() {
        mixpanel.track('Home Play see Create class modal');
    },
    emailModalClickCopy() {
        mixpanel.track('Student in class modal click Copy');
    },
    emailModalClickClose() {
        mixpanel.track('Student in class modal click Close');
    },
    clickedGameNightCTA({ testGroup }) {
        mixpanel.track('Clicked Teacher Gamenight Banner CTA', {
            'Test group': testGroup,
        });
    },
    closedGameNightBanner({ testGroup }) {
        mixpanel.track('Closed Teacher Gamenight Banner', {
            'Test group': testGroup,
        });
    },
};
