<template>
    <div
        class="c-avatar__container"
        :style="{
            width: avatarSize ? avatarSize + 'px' : '100%',
            minWidth: avatarSize ? avatarSize + 'px' : '100%',
            height: avatarSize ? avatarSize + 'px' : '100%',
            minHeight: avatarSize ? avatarSize + 'px' : '100%',
        }"
    >
        <div
            class="c-avatar"
            :class="[
                !avatarData?.background &&
                    !withoutBackground &&
                    `c-avatar--rarity-${rarity}`,
                {
                    'c-avatar--clickable': changeAvatar,
                    'c-avatar-has-crown':
                        hasCrown && avatarData?.avatar !== 'custom',
                    'frame-outline': !!myFrameBorder,
                    'custom-avatar': avatarData?.avatar === 'custom',
                },
            ]"
            :style="{
                'background-image':
                    avatarData?.background !== '#572598'
                        ? 'url(/images/avatars/home-game/backgrounds/' +
                          avatarData?.background +
                          '.svg?2)'
                        : 'none',
                background:
                    (withoutBackground && '#572598') ||
                    (isTransparent && 'transparent') ||
                    (avatarData?.background === '#572598' && '#572598'),
                boxShadow: `0 0 0 ${borderWidth}px ${borderColor}`,
            }"
            @click="changeAvatar ? changeAvatarClick() : null"
        >
            <div
                v-if="changeAvatar"
                class="change-avatar-icon"
                @click="changeAvatarClick"
            />
            <img
                v-if="avatarData?.avatar !== 'custom'"
                :src="`/images/avatars/home-game/${getAvatarName(
                    avatarData?.avatar,
                )}.png?upd`"
                :alt="avatarData?.avatar"
                class="c-avatar__img"
            />
            <div
                v-if="emoji && showEmojiAnimation && render"
                class="c-avatar__emoji"
            >
                <Confetti
                    v-if="emoji === 'confetti'"
                    class="c-avatar__emoji-confetti"
                />
                <Drop
                    v-else-if="emoji === 'tears'"
                    class="c-avatar__emoji-drop"
                />
                <Heart
                    v-else-if="emoji === 'heart'"
                    class="c-avatar__emoji-heart"
                />
                <Heureka
                    v-else-if="emoji === 'eureka'"
                    class="c-avatar__emoji-heureka"
                />
                <Thumb
                    v-else-if="emoji === 'thumbsup'"
                    class="c-avatar__emoji-thumb"
                />
            </div>
        </div>
        <CustomAvatarItem
            v-if="avatarData?.avatar === 'custom'"
            :custom-avatar="avatarData?.customAvatar"
            :avatar-size="avatarSize"
            :class="{
                'custom-avatar-has-crown': hasCrown,
            }"
        />
        <img
            v-if="avatarData?.frame && avatarData?.frame !== 'no-frame'"
            :src="`/images/avatars/home-game/frames/${avatarData?.frame}.png?3`"
            :alt="avatarData?.frame"
            class="c-avatar__frame"
            :class="{
                'custom-avatar-frame': avatarData?.avatar === 'custom',
            }"
        />
    </div>
</template>
<script>
import getAvatarsList3d from '@/core/static-json/avatar/AvatarsList3d.json';
import getBackgroundsList from '@/core/static-json/avatar/BackgroundsList.json';
import getFramesList from '@/core/static-json/avatar/FramesList.json';
import Confetti from './EmojiConsumables/Confetti.vue';
import Drop from './EmojiConsumables/Drop.vue';
import Heart from './EmojiConsumables/Heart.vue';
import Heureka from './EmojiConsumables/Heureka.vue';
import Thumb from './EmojiConsumables/Thumb.vue';
import { mapGetters } from 'vuex';
import CustomAvatarItem from '@/flows/Avatar/CustomAvatarItem.vue';

export default {
    components: {
        Confetti,
        Drop,
        Heart,
        Heureka,
        Thumb,
        CustomAvatarItem,
    },
    props: {
        forceUserData: { type: Boolean, default: false },
        avatar: { type: String, default: 'custom' },
        customAvatar: [Object, String],
        avatarSize: { type: Number, default: 0 },
        changeAvatar: { type: Boolean, default: false },
        background: { type: String, default: '' },
        clientId: { type: String, default: '' },
        withoutBackground: { type: Boolean, default: false },
        isTransparent: { type: Boolean, default: false },
        frame: { type: String, default: '' },
        borderWidth: { type: Number, default: 0 },
        borderColor: { type: String, default: '' },
        hasCrown: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            emoji: '',
            render: true,
            framesList: getFramesList,
            avatarsList3d: getAvatarsList3d,
            backgroundsList: getBackgroundsList,
            avatarsMap: {},
        };
    },
    computed: {
        ...mapGetters(['user']),
        ...mapGetters('v2/soloTrack', {
            soloTrackData: 'studentSoloTrackData',
        }),
        ...mapGetters('v2/student', ['userAvatarData']),
        avatarData() {
            if (this.forceUserData) {
                return {
                    avatar:
                        this.avatar || this.userAvatarData?.avatar || 'custom',
                    customAvatar:
                        this.customAvatar || this.userAvatarData?.customAvatar,
                    background:
                        this.background ||
                        this.userAvatarData?.background ||
                        '#572598',
                    frame:
                        this.frame || this.userAvatarData?.frame || 'no-frame',
                };
            }

            const dataFromProps = {
                avatar: this.avatar || 'custom',
                customAvatar: this.customAvatar,
                background: this.background || '#572598',
                frame: this.frame || 'no-frame',
            };

            let returnDataFromProps = false;

            for (const [_key, value] of Object.entries(dataFromProps)) {
                if (value) {
                    if (
                        value !== 'no-frame' &&
                        value !== '#572598' &&
                        value !== 'custom'
                    ) {
                        returnDataFromProps = true;
                        break;
                    }
                }
            }

            if (returnDataFromProps) {
                return {
                    ...dataFromProps,
                    customAvatar: {
                        ...dataFromProps.customAvatar,
                        body: dataFromProps.customAvatar?.body || 1,
                    },
                };
            } else {
                return {
                    ...this.userAvatarData,
                    avatar: this.userAvatarData?.avatar || 'custom',
                    customAvatar: {
                        ...this.userAvatarData?.customAvatar,
                        body: this.userAvatarData?.customAvatar?.body || 1,
                    },
                };
            }
        },
        v2() {
            return (
                this.$store.state.v2.game.mode ===
                this.$CONSTANTS.LIVE_GAME_MODE
            );
        },
        rarity() {
            let rarityClass;

            if (this.avatarData?.avatar === 'custom') {
                rarityClass = 'common';
            } else if (this.avatarData?.avatar) {
                rarityClass =
                    this.avatarsList3d
                        .map((group) => {
                            return group.avatars;
                        })
                        .flat()
                        .find((item) => item.id === this.avatarData?.avatar)
                        ?.rarity || '';
            } else if (this.avatarData?.background) {
                rarityClass =
                    this.backgroundsList.find(
                        (item) => item.id === this.avatarData?.background,
                    ).rarity || '';
            }

            return rarityClass;
        },
        showEmojiAnimation() {
            const routes = [
                'host.lobby',
                'livegame.preview',
                'livegame.gameview',
                'livegame.results',
                'host.results',
                'host.play',
            ];

            return routes.includes(this.$route.name);
        },
        myFrameBorder() {
            return (
                this.framesList.find(
                    (item) => item.id === this.avatarData?.frame,
                )?.border || 'transparent'
            );
        },
    },
    beforeUnmount() {
        let socket;

        if (this.v2) {
            socket = this.$store.getters['v2/io/currentSocket']?.socket;
        } else {
            socket = this.$socket.client;
        }

        if (!socket) return;

        socket.off('playerSelectedEmoji');
    },
    created() {
        this.avatarsList3d
            .flatMap((group) => group.avatars)
            .forEach(
                (avatarInfo) => (this.avatarsMap[avatarInfo.id] = avatarInfo),
            );
    },
    mounted() {
        if (this.v2) {
            this.$store.getters['v2/io/currentSocket']?.socket?.on(
                'playerSelectedEmoji',
                this.animate,
            );
        } else {
            this.$socket.client.on('playerSelectedEmoji', this.animate);
        }
    },
    methods: {
        animate(data) {
            if (data.clientId === this.clientId) {
                console.log('animating', data);

                this.emoji = data.emoji;

                this.render = false;

                setTimeout(() => {
                    this.render = true;
                }, 0);
            }
        },
        changeAvatarClick() {
            this.$router.push({ name: 'home-game.v10.avatars-page' });
        },
        getAvatarName(avatar) {
            return this.avatarsMap[avatar]?.name || 'default';
        },
    },
};
</script>
<style lang="scss">
.c-avatar {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 50% 50% !important;
    z-index: 1;

    &-has-crown {
        &::before {
            content: '';
            display: block;
            background: url('/images/icons/icon-crown.svg') no-repeat;
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            z-index: 100;
            width: calc(100% / 2);
            height: calc(100% / 2);
            border: 0 !important;
            border-radius: 0 !important;
            left: 50% !important;
        }
    }

    .change-avatar-icon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 5px;
        left: 5px;
        width: 36px;
        height: 39px;
        object-fit: contain;

        &::after {
            content: '';
            display: flex;
            width: 36px;
            height: 39px;
            background: url('/images/path-footer/edit-name-icon.svg') no-repeat;
        }

        @media screen and (max-width: $w-max-sm) {
            transform: translateX(-20px);
        }
    }

    &--clickable {
        cursor: pointer;
    }

    &--rarity-common {
        background-color: #8350d8;
    }

    &--rarity-rare {
        background-color: #3896ee;
    }

    &--rarity-epic {
        background-color: #53da60;
    }

    &--rarity-legendary {
        background-color: #fca62f;
    }

    &__img {
        display: block;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    &__frame {
        position: absolute;
        display: block;
        object-fit: contain;
        height: 150%;
        transform: translateY(-16%);
    }

    &__container {
        position: relative;
        display: flex;
        justify-content: center;
    }

    &__emoji {
        position: absolute;

        &-confetti {
            position: relative;
            left: 0;

            svg {
                width: 140%;
            }
        }

        &-drop {
            position: relative;
            right: -40%;
            top: 25px;

            svg {
                width: 115%;
            }
        }

        &-heart {
            position: relative;
            left: -4px;
            bottom: 0;

            svg {
                width: 115%;
            }
        }

        &-heureka {
            position: relative;
            left: -35%;
            top: -30px;

            svg {
                width: 140%;
            }
        }

        &-thumb {
            position: relative;
            left: -35%;
            top: 24px;

            svg {
                width: 115%;
            }
        }
    }
}
.frame-outline {
    &::before {
        content: '';
        position: absolute;
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
        border: 4px solid v-bind(myFrameBorder);
        border-radius: 50%;
    }
}
@keyframes animationAppear {
    0% {
        top: 115%;
        opacity: 0;
    }
    50% {
        top: 50%;
        opacity: 1;
    }
    100% {
        top: -15%;
        opacity: 0;
    }
}
.custom-avatar {
    position: absolute;
    bottom: 0;
    height: 68% !important;
    width: 68% !important;

    &-has-crown {
        &::before {
            content: '';
            display: block;
            background: url('/images/icons/icon-crown.svg') no-repeat;
            position: absolute;
            transform: translateX(-50%) translateY(-75%);
            z-index: 10;
            width: calc(100% / 2);
            height: calc(100% / 2);
            border: 0 !important;
            border-radius: 0 !important;
            left: 50% !important;
        }
    }
}
.custom-avatar-frame {
    bottom: -25%;
    height: 114%;
    transform: unset;
}
</style>
