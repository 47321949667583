export const HomeGameRoutes = [
    {
        path: '/home-game',
        name: 'home-game.v10',
        component: () => import('./pages/HomeGameContainer.vue'),
        children: [
            {
                path: 'burger',
                name: 'home-game.v10.burger',
                component: () => import('@/student/pages/BurgerMenuView.vue'),
                props: true,
            },
            {
                path: '',
                name: 'home-game.v10.main-page',
                component: () => import('@/student/pages/MainPage.vue'),
                props: true,
                meta: {
                    authenticate: true,
                },
            },
            {
                path: 'linear-skills-map-page',
                name: 'home-game.v10.linear-skills-map-page',
                component: () => import('./pages/_legacy/LinearSkillsMap.vue'),
                props: true,
                meta: {
                    authenticate: true,
                },
            },
            {
                path: 'topics-page',
                name: 'home-game.v10.topics-page',
                component: () =>
                    import('@/student/pages/_legacy/TopicsPage.vue'),
                props: true,
                meta: {
                    authenticate: true,
                },
            },
            {
                path: 'multiplayer-topics-page',
                name: 'home-game.v10.multiplayer-topics-page',
                component: () =>
                    import('../flows/MathRunner/MultiplayerTopicsPage.vue'),
                props: true,
                meta: {
                    authenticate: true,
                },
            },
            {
                path: 'skills-page',
                name: 'home-game.v10.skills-page',
                component: () =>
                    import('@/student/pages/_legacy/SkillsPage.vue'),
                props: true,
            },
            {
                path: 'multiplayer-skills-page',
                name: 'home-game.v10.multiplayer-skills-page',
                component: () =>
                    import('@/student/pages/_legacy/SkillsPage.vue'),
                props: true,
            },
            {
                path: 'game',
                name: 'home-game.v10.game-page',
                component: () => import('./pages/GamePage.vue'),
                props: true,
            },
            {
                path: 'game-battery',
                name: 'home-game.v10.game-page-battery',
                component: () => import('./pages/GamePageBattery.vue'),
                props: true,
            },
            {
                path: 'game',
                name: 'home-game.v10.program-game',
                component: () => import('@/student/widgets/SoloTrackGame.vue'),
                props: true,
            },
            {
                path: 'game/end',
                name: 'home-game.v10.game-end',
                component: () => import('@/student/flows/GameEnd/GameEnd.vue'),
                props: true,
            },
            {
                path: 'avatars-page',
                name: 'home-game.v10.avatars-page',
                component: () =>
                    import('@/student/pages/_legacy/AvatarsPage.vue'),
                props: true,
            },
            {
                path: 'leaderboard-page/:classCode',
                name: 'home-game.v10.leaderboard-page.class',
                component: () =>
                    import('./pages/_legacy/ClassesLeaderboard.vue'),
                props: true,
            },
            {
                path: 'leaderboard-page',
                name: 'home-game.v10.leaderboard-page',
                component: () =>
                    import('./pages/_legacy/ClassesLeaderboard.vue'),
                props: true,
            },
            {
                path: 'battle-pass',
                name: 'home-game.v10.battle-pass',
                component: () =>
                    import(
                        '@/student/pages/BattlePass/BattlePassHorizontal.vue'
                    ),
                props: true,
            },
            {
                path: 'assignments',
                name: 'home-game.v10.assignments',
                component: () => import('./pages/HomeAssignments.vue'),
                props: true,
            },
            {
                path: 'daily-rewards',
                name: 'home-game.v10.daily-rewards',
                component: () =>
                    import('@/student/pages/_legacy/DailyRewardPage.vue'),
                props: true,
                meta: {
                    duration: { enter: 1000, leave: 1000 },
                },
            },
            {
                path: 'social-page',
                name: 'home-game.v10.social-page',
                component: () =>
                    import('@/student/pages/_legacy/SocialPage.vue'),
                props: true,
            },
            {
                path: 'collection',
                name: 'home-game.v10.collection',
                component: () =>
                    import('@/student/flows/Collection/CollectionPage.vue'),
                props: true,
            },
            {
                path: 'my-avatar',
                name: 'home-game.v10.my-avatar',
                component: () =>
                    import('@/student/flows/MyAvatar/MyAvatarPage.vue'),
                props: true,
            },
            {
                path: 'choose-avatar',
                name: 'home-game.v10.choose-avatar',
                component: () =>
                    import(
                        '@/student/flows/MyAvatar/components/FTUEChooseAvatar.vue'
                    ),
                props: true,
            },
            {
                path: 'zoo',
                name: 'home-game.v10.zoo',
                component: () => import('@/student/pages/Park/OwnParkPage.vue'),
                props: true,
            },
            {
                path: 'shop',
                name: 'home-game.v10.shop',
                component: () => import('@/student/pages/_legacy/ShopPage.vue'),
                props: true,
            },
            {
                path: 'pay/:productId',
                name: 'home-game.v10.pay',
                component: () =>
                    import('@/flows/PaymentModal/OneTimePurchaseModal.vue'),
                props: true,
            },
            {
                path: 'student-park/:userId',
                name: 'home-game.v10.student-park',
                component: () =>
                    import('@/student/pages/Park/GuestParkPage.vue'),
                props: true,
            },
            {
                path: 'friends',
                name: 'home-game.v10.friends',
                component: () =>
                    import('@/student/pages/_legacy/FriendsPage.vue'),
                props: true,
            },
            {
                path: 'program',
                name: 'home-game.v10.program',
                component: () =>
                    import('@/student/flows/ProgramPage/ProgramPage.vue'),
                props: true,
                meta: {
                    authenticate: true,
                },
            },
            {
                path: 'cards',
                name: 'home-game.v10.cards',
                component: () => import('@/student/flows/Cards/CardsPage.vue'),
                props: true,
                meta: {
                    authenticate: true,
                },
            },
            {
                // @deprecated
                path: 'solo-game',
                name: 'home-game.v10.solo-game',
                component: () =>
                    import('@/student/pages/SoloGame/SoloGamePage.vue'),
                props: true,
            },
            {
                path: 'solo-game-island',
                name: 'home-game.v10.solo-game-island',
                component: () =>
                    import('@/student/pages/Island/IslandPage.vue'),
                props: true,
            },
            {
                path: 'my-islands',
                name: 'home-game.v10.my-islands',
                component: () =>
                    import('@/student/pages/Island/MyIslandPage.vue'),
                props: true,
            },
            {
                path: 'welcome',
                name: 'home-game.v10.welcome-loader',
                component: () =>
                    import('@/student/pages/Welcome/WelcomeLoading.vue'),
                props: true,
                meta: {
                    authenticate: true,
                    duration: { leave: 800 },
                },
            },
            {
                path: 'welcome-creds',
                name: 'home-game.v10.welcome-preloader',
                component: () =>
                    import('@/student/pages/Welcome/WelcomePreLoading.vue'),
                props: true,
                meta: {
                    authenticate: true,
                    duration: { leave: 800 },
                },
            },
            {
                path: 'welcome-grades',
                name: 'home-game.v10.welcome-grades',
                component: () =>
                    import(
                        '@/student/pages/Welcome/WelcomeGradeSelectionView.vue'
                    ),
                props: true,
                meta: {
                    authenticate: true,
                    duration: { leave: 800 },
                },
            },
            {
                path: 'welcome-name',
                name: 'home-game.v10.welcome-name',
                component: () =>
                    import('@/student/pages/Welcome/WelcomeUsername.vue'),
                props: true,
                meta: {
                    authenticate: true,
                    duration: { enter: 1500, leave: 2000 },
                },
            },
            {
                path: 'welcome-avatar',
                name: 'home-game.v10.welcome-avatar',
                component: () =>
                    import('@/student/pages/Welcome/WelcomeAvatar.vue'),
                props: true,
                meta: {
                    authenticate: true,
                    duration: { enter: 1500, leave: 3000 },
                },
            },
            {
                path: 'join-class',
                name: 'home-game.v10.join-class',
                component: () => import('./pages/JoinClass.vue'),
                props: true,
                meta: {
                    authenticate: true,
                    duration: { enter: 1500, leave: 3000 },
                },
            },
            {
                path: 'energy-status',
                name: 'home-game.v10.energy-status',
                component: () => import('./pages/OutOfEnergyView.vue'),
                props: true,
                meta: {
                    authenticate: true,
                    duration: { enter: 1500, leave: 3000 },
                },
            },
            {
                path: 'math-pass',
                name: 'home-game.v10.math-pass',
                component: () => import('./pages/MathPassPage.vue'),
                props: true,
                meta: {
                    authenticate: true,
                    duration: { enter: 1500, leave: 3000 },
                },
            },
        ],
    },
    {
        path: '/solo-game-island-map',
        name: 'home-game.v10.solo-game-island-map',
        component: () => import('@/student/pages/Island/IslandMapPage.vue'),
        props: true,
        meta: {
            authenticate: true,
        },
    },
    {
        path: '/island-reward/:island',
        name: 'home-game.v10.island-reward',
        component: () => import('@/student/pages/Island/IslandRewardPage.vue'),
        props: true,
        meta: {
            authenticate: true,
        },
    },
    {
        path: '/storyboard',
        name: 'storyboard',
        component: () => import('@/student/pages/_StoryBoard.vue'),
    },
];
